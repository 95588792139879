import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import {
  Box,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  AspectRatio,
  Button,
  Input,
  SimpleGrid,
  Heading,
  Image,
  Center,
  Stack,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { useAuth } from 'context/auth';

export default function Login() {
  const { isLoading, isAuthenticated, isAdmin, isSuperUser, loginRequest } = useAuth();

  const router = useRouter();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const Elements = event.currentTarget.elements;
    const { value: username } = Elements.namedItem('username') as RadioNodeList;
    const { value: password } = Elements.namedItem('password') as RadioNodeList;

    if (username && password) {
      loginRequest({ username, password });
    } else {
      alert('Enter your id and password');
    }
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      if (isSuperUser) {
        router.replace('/super-user');
      } else if (isAdmin) {
        router.replace('/admin');
      } else {
        router.replace('/bas');
      }
    }
  }, [isAuthenticated, isLoading, isSuperUser, isAdmin, router]);

  return (
    <>
      <SimpleGrid columns={[1, 1, 1, 1, 2]} spacingX="0px" spacingY="0px">
        <Box h="100vh" bg="white" backgroundImage="url('wave.png')" backgroundPosition="bottom" backgroundSize="cover" backgroundRepeat="no-repeat">
          <Flex color="#342e87" direction="column" justify="center" align="center">
            <Center paddingTop="14vh">
              <Image src="logo.png" boxSize="35vh" objectFit="cover" />
            </Center>
            <Center paddingTop="17vh">
              <Heading fontSize="4.5em" fontWeight="normal" fontFamily="ui-sans-serif" color="white">
                Automous Navigation System
              </Heading>
            </Center>
            <Center paddingTop="2vh">
              <Heading fontSize="1.5em" color="#90a2bd">
                AI-based control system by Avikus Inc.
              </Heading>
            </Center>
          </Flex>
        </Box>
        <Box h="100vh" bg="#342e87">
          <Flex color="#342e87" direction="column" justify="center" align="center">
            <Center paddingTop="15vh">
              <Image src="small-logo.png" width="30vh" objectFit="cover" background="#342e87" />
            </Center>
            <Center paddingTop="5vh">
              {!isAuthenticated && (
                <form onSubmit={onSubmit}>
                  <Flex flexDirection="column">
                    <VStack spacing="18px">
                      <FormControl id="username">
                        <FormLabel>Id</FormLabel>
                        <Input
                          placeholder="id"
                          variant="unstyled"
                          minW="340px"
                          p="16px 20px"
                          fontSize="1.5em"
                          borderRadius="5px"
                          border="1px solid #bebebe"
                          backgroundColor="white"
                          _focus={{ outline: 'none' }}
                        />
                      </FormControl>

                      <FormControl id="password">
                        <FormLabel>Password</FormLabel>
                        <Input
                          type="password"
                          placeholder="password"
                          variant="unstyled"
                          minW="25em"
                          p="0.75em 1em"
                          fontSize="1.5em"
                          borderRadius="5px"
                          border="1px solid #bebebe"
                          backgroundColor="white"
                          _focus={{ outline: 'none' }}
                        />
                      </FormControl>
                    </VStack>

                    <Button
                      type="submit"
                      variant="unstyled"
                      mt="84px"
                      width="100%"
                      height="70px"
                      color="white"
                      fontSize="1.7em"
                      fontWeight="bold"
                      backgroundColor="#333790"
                      borderRadius="999px"
                      _focus={{ boxShadow: 'none' }}
                    >
                      Login
                    </Button>
                  </Flex>
                </form>
              )}
            </Center>
          </Flex>
        </Box>
      </SimpleGrid>
    </>
  );
}
